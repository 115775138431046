<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />
    <div class="addUser">
      <div class="row">
        <div class="col-lg-12 col-12">
          <div class="card">
            <div class="card-body">
              <form ref="form" @submit.prevent="submit">
                <div class="row">
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Firstname-input"
                        >First Name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model.trim="$v.form.first_name.$model"
                        id="formrow-Firstname-input"
                        placeholder="Enter Your First Name"
                      />
                      <div v-if="$v.form.first_name.$error">
                        <div class="error" v-if="!$v.form.first_name.required">
                          First Name is Required!
                        </div>
                        <!-- <div class="error" v-if="!$v.form.first_name.alpha">
                          Please Enter Letters only
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Lastname-input"
                        >Last Name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model.trim="$v.form.last_name.$model"
                        id="formrow-Lastname-input"
                        placeholder="Enter Your Last Name"
                      />
                      <div class="error" v-if="$v.form.last_name.$error">
                        <div class="error" v-if="!$v.form.last_name.required">
                          Last Name is Required!
                        </div>
                        <!-- <div class="error" v-if="!$v.form.last_name.alpha">
                          Please Enter Letters only
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-email-input"
                        >Email</label
                      >
                      <input
                        type="email"
                        class="form-control"
                        v-model.trim="$v.form.email.$model"
                        id="formrow-email-input"
                        placeholder="Enter Your Email Address"
                      />
                      <div class="error" v-if="$v.form.email.$error">
                        <div class="error" v-if="!$v.form.email.required">
                          Email is Required!
                        </div>
                        <div class="error" v-if="!$v.form.email.email">
                          Please Enter Valid Email
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Contact-input"
                        >Contact No.</label
                      >
                      <input
                        type="tel"
                        class="form-control"
                        v-model.trim="$v.form.mobile.$model"
                        id="formrow-Contact-input"
                        placeholder="(907) 555-0101"
                      />
                      <div class="error" v-if="$v.form.mobile.$error">
                        <div class="error" v-if="!$v.form.mobile.required">
                          Mobile Number is Required!
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Password"
                        >Password</label
                      >
                      <input
                        type="password"
                        class="form-control"
                        v-model.trim="$v.form.password.$model"
                        id="formrow-Password"
                        placeholder="Enter Password"
                      />
                      <div class="error" v-if="$v.form.password.$error">
                        <div class="error" v-if="!$v.form.password.required">
                         Password is Required!
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="form-label" for="c-password"
                        >Confirm Password</label
                      >
                      <input
                        type="password"
                        class="form-control"
                        v-model.trim="$v.form.confirmPassword.$model"
                        id="c-password"
                        placeholder="Enter Confirm Password"
                      />
                      <div class="error" v-if="$v.form.confirmPassword.$error">
                        <!-- <div class="error" v-if="!$v.form.confirmPassword.required">
                          Confirm Password is Required!
                        </div> -->
                        <div class="error" v-if="!$v.form.confirmPassword.sameAsPassword">
                          Password and Confirm Password Should Match
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="form-check">
                                                      <input type="checkbox" class="form-check-input" id="formrow-customCheck">
                                                      <label class="form-check-label" for="formrow-customCheck">Check me out</label>
                </div>-->

                <div class="d-flex flex-wrap gap-3 mt-3">
                  <SubmitButton
                    :processing="processing"
                    type="submit"
                    class="btn btn-primary waves-effect waves-light w-md"
                    text="Add User"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import { required, email, alpha,sameAs } from "vuelidate/lib/validators";

export default {
  page: {
    title: "Add User",
    meta: [
      {
        name: "add-user",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      processing: false,
      form: {
        first_name: null,
        last_name: null,
        email: null,
        mobile: null,
        password:null,
        confirmPassword:null,
      },
      title: "Add User",
      items: [
        {
          text: "Omaha",
        },
        {
          text: "Add User",
          active: true,
        },
      ],
    };
  },
  validations: {
    form: {
      first_name: {
        required,
        // alpha,
      },
      last_name: {
        required,
        // alpha,
      },
      email: {
        email,
        required,
      },
      mobile: {
        required,
      },
      password:{
        required,
      },
      confirmPassword:{
        required,
        sameAsPassword: sameAs('password')
      }
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        let self = this;
        self.processing = true;
        console.log(this.form);
        this.$axios
          .post("create-user", this.form)
          .then((response) => {
            this.$router.push({ path: "/users/listing" });
            this.triggerSwal(response.data.message, "success");
            this.$refs.form.reset();
            this.processing = false;
          })
          .catch((error) => {
            this.handleErrorResponse(error.response, "error");
            this.processing = false;
          });
      }
    },
  },
};
</script>